import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const AlertsError = ({ message, modalState, toggleModal }) => {
  return (
    <React.Fragment>
      <div>
        <Modal isOpen={modalState} toggle={() => toggleModal()} centered fade={true}>
          <ModalBody>
            <div className='d-flex justify-content-between align-items-center' style={{ flexDirection: 'column' }}>
              <div style={{ margin: '18px 0' }}>
                <svg enableBackground="new 0 0 48 48"
                  height="60px" id="Layer_1" version="1.1" viewBox="0 0 48 48"
                  width="60px"><g id="Layer_3"><g>
                    <path d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M24,44    
            C12.954,44,4,35.046,4,24S12.954,4,24,4s20,8.954,20,20S35.046,44,24,44z"
                      fill="#FE5E78" /><polygon fill="#FE5E78"
                        points="33.878,12.003 24,21.88 14.123,12.003 12.003,14.125 21.879,24.001 12.003,33.877 14.126,35.997     
            24,26.123 33.875,35.997 35.997,33.877 26.122,24.001 35.997,14.125 "/></g></g>
                </svg>
              </div>
              <h5 className='mt-2' style={{ color: '#FE5E78', fontWeight: '600', fontSize: '24px' }}>Oops!</h5>
              <p className='mt-3' style={{ fontSize: '18px' }}>{message}</p>
              <button className='btn w-100 mt-3'
                style={{ backgroundColor: '#FE5E78', color: 'white', fontWeight: '600', maxHeight: '48px' }}
                onClick={() => toggleModal()}
              >
                Thử lại
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AlertsError;

