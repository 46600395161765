import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { checkEmailFormat } from "../../common/checkValidate/checkEmail";
import { forgotPassword, resetPassword } from "../../api/apiAccount";
import resetPasswordImage from "../../assets/images/auth/reset-password.webp";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import ScrolltoTop from "../../components/ScrolltoTop";
import Spinners from "./Components/Spinners";
import AlertsError from "./Components/AlertsError";
import { MESSAGE_VALIDATE } from "../../common/constants/Messages";


const ResetPasswordNewPass = () => {
  const { t } = useTranslation("translation");
  document.title = t("signin.quenmatkhau");

  const logo = 'https://wolrdjobstorage.blob.core.windows.net/assets/top_logo.png';

  let [email, setEmail] = useState('');
  let [resetCode, setResetCode] = useState('');
  let [newPassword, setNewPassword] = useState('');
  let [passwordtype, setPasswordType] = useState('password');
  let [valid, setValid] = useState(true);
  let [loading, setLoading] = useState(false);
  let [alertdanger, setAlertDanger] = useState('');
  const openDanger = () => setAlertDanger(!alertdanger);

  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const getEmail = query.get('email');
    const getCode = query.get('code');
    setEmail(getEmail);
    setResetCode(getCode);
  }, []);

  const handleRequest = () => {
    setLoading(true);
    setAlertDanger(false);
    resetPassword(email, resetCode, newPassword).then((res) => {
      if (res.status === 200) {
        navigate("/signin");
      } else {
        setAlertDanger(true);
      }
      setLoading(false);
    });
  };

  const handleShowPassword = () => {
    passwordtype === 'password' ? setPasswordType('text') : setPasswordType('password');
  };

  const handleNewPassword = event => {
    setNewPassword(event.target.value);
    if (event.target.value) {
      setValid(false);
    }
  }

  return (
    <React.Fragment>
      {alertdanger && <AlertsError message={MESSAGE_VALIDATE.reset_password_error} modalState={alertdanger} toggleModal={openDanger} />}
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={logo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                src={logo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          {loading && <Spinners />}
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>{t("signin.quenmatkhau")}</h5>
                              </div>
                              <div className="auth-form text-white">
                                <div className="mb-4">
                                  <label className="form-label" htmlFor="email">
                                    {t("account.matkhaumoi")}
                                  </label>
                                  <Input
                                    type={passwordtype}
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder="Nhập mật khẩu mới"
                                    onChange={handleNewPassword}
                                    value={newPassword}
                                  />
                                  <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10, color: 'black' }} onClick={handleShowPassword}>
                                    {passwordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-white w-100"
                                    onClick={handleRequest}
                                    disabled={valid}
                                  >
                                    {t("forgetpass.guiyeucau")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <ScrolltoTop />
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordNewPass;
