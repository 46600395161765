import { refreshToken, originalGetRequest, originalPostRequestJson, originalPostRequest } from "../api/apiRefreshToken";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage, setStorage } from "../common/constants/Services";


export async function customFetch(url, data, method, resformat) {
    let tokenRefresh = getStorage(STORAGE_KEYS.REFRESH_TOKEN);
    let expiredToken = getStorage(STORAGE_KEYS.EXPIRED_TOKEN);

    let date = new Date();
    let isExpired = Math.round((expiredToken - date.getTime()) / 1000);
    if (isExpired <= 60) {
        await refreshToken(tokenRefresh).then((res) => {
            if (res.status && res.status != 200) {
                window.location.href = "/signout";
            } else {
                setStorage(STORAGE_KEYS.ACCESS_TOKEN, res.accessToken);
                setStorage(STORAGE_KEYS.REFRESH_TOKEN, res.refreshToken);
                let expiredTime = new Date();
                const seconds = expiredTime.getSeconds() + res.expiresIn;
                expiredTime.setSeconds(seconds);
                setStorage(STORAGE_KEYS.EXPIRED_TOKEN, expiredTime.getTime());
            }
        }, (err) => {
            window.location.href = "/signout";
            console.log('err: ', err);
        })
    }

    let response = (method === 'POST') ? (resformat === 'json' ? originalPostRequestJson(url, data, method) : originalPostRequest(url, data, method))
        : originalGetRequest(url, method);
    return response;
}