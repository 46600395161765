import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./en.json";
import Vietnamese from "./vi.json";
import Japanese from "./jp.json";
import Korean from "./kr.json";
import Germany from "./gr.json";

const resources = {
    en: {
        translation: English,
    },
    vi: {
        translation: Vietnamese,
    },
    jp: {
        translation: Japanese,
    },
    kr: {
        translation: Korean,
    },
    gr: {
        translation: Germany,
    },
};

const currentLanguage = localStorage.getItem("lng") ? localStorage.getItem("lng") : "vi";
i18n.use(initReactI18next).init({
    resources,
    lng: currentLanguage,
    fallbackLng: "vi",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;