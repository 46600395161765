
import VietNam from "../../assets/images/flags/vietnam.jpg";
import Japan from "../../assets/images/flags/japan.jpg";
import Korea from "../../assets/images/flags/korea.jpg";
import Germany from "../../assets/images/flags/germany.jpg";
import Singapore from "../../assets/images/flags/singapore.jpg";
import Qatar from "../../assets/images/flags/qatar.jpg";
import Cyprus from "../../assets/images/flags/cyprus.jpg";
import Taiwan from "../../assets/images/flags/taiwan.jpg";

let countries = [
    { id: 1, image: VietNam },
    { id: 2, image: Japan },
    { id: 3, image: Korea },
    { id: 4, image: Germany },
    { id: 6, image: Taiwan },
    { id: 8, image: Singapore },
    { id: 9, image: Qatar },
    { id: 65, image: Cyprus }
];

export const checkIconCountry = (countryData, cityId) => {
    let countryImage = [];
    countryData && countryData.map((item, key) => {
        item.cities.map((itemCity, keyCity) => {
            if (itemCity.id == cityId) {
                countryImage = countries.filter(c => c.id == itemCity.countryID);
            }
        })
    })
    return countryImage.length ? countryImage[0].image : "";
}