import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Card, Col, Input, Row, CardBody } from "reactstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { registerAccount, login } from "../../../api/apiAccount";
import { checkEmailFormat } from "../../../common/checkValidate/checkEmail";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { setStorage } from "../../../common/constants/Services";
import ScrolltoTop from "../../../components/ScrolltoTop";
import { useTranslation } from "react-i18next";
import Spinners from "../Components/Spinners";
import Alerts from "../Components/Alerts";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { APP_CONST } from "../../../common/constants/Constants";
import sigupImage from "../../../assets/images/signup.webp";
import bannerImage from "../../../assets/images/banner.webp";

const SignUpEmployeeContent = () => {
  document.title = APP_CONST.APP_NAME;

  const { t } = useTranslation("translation");
  const { executeRecaptcha } = useGoogleReCaptcha();
  let [username, setUserName] = useState('');
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [passwordtype, setPasswordType] = useState('password');
  let [confirmPassword, setConfirmPassword] = useState('');
  let [confirmpasswordtype, setConfirmPasswordType] = useState('password');
  let [confirm, setConfirm] = useState(false);
  let [invalid, setInvalid] = useState(true);


  //check validate
  let [usernameErr, setUserNameErr] = useState('');
  let [emailErr, setEmailErr] = useState('');
  let [passwordErr, setPasswordErr] = useState('');
  let [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  let [loading, setLoading] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [duplicateEmail, setDuplicateEmail] = useState(false);

  const didMount = useRef(false);

  const navigate = useNavigate();

  const handleUserName = event => {
    setUserName(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('username');
  }, [username]);

  const handleEmail = event => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('email');
  }, [email]);

  const handlePassword = event => {
    setPassword(event.target.value.trim());
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('password');
  }, [password]);

  const handleShowPassword = () => {
    passwordtype === 'password' ? setPasswordType('text') : setPasswordType('password');
  }

  const handleConfirmPassword = event => {
    setConfirmPassword(event.target.value.trim());
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('confirmPassword');
  }, [confirmPassword]);

  const handleShowConfirmPassword = () => {
    confirmpasswordtype === 'password' ? setConfirmPasswordType('text') : setConfirmPasswordType('password');
  }

  const handleConfirm = event => {
    setConfirm(event.target.checked);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('confirm');
  }, [confirm]);

  const checkValidate = (value) => {
    let checkUserName = true;
    let checkEmail = true;
    let checkPass = true;
    let checkConfirmPass = true;
    let checkConfirm = true;
    if (value === 'username') {
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      !password ? checkPass = true : checkPass = false;
      (!confirmPassword || confirmPassword !== password) ? checkConfirmPass = true : checkConfirmPass = false;
      confirm ? checkConfirm = false : checkConfirm = true;
      if (!username) {
        setUserNameErr('Vui lòng nhập tên ứng viên');
        checkUserName = true;
      } else {
        setUserNameErr('');
        checkUserName = false;
      }
    }
    if (value === 'email') {
      !username ? checkUserName = true : checkUserName = false;
      !password ? checkPass = true : checkPass = false;
      (!confirmPassword || confirmPassword !== password) ? checkConfirmPass = true : checkConfirmPass = false;
      confirm ? checkConfirm = false : checkConfirm = true;
      if (!checkEmailFormat(email)) {
        setEmailErr('Định dạng email chưa chính xác');
        checkEmail = true;
      } else {
        setEmailErr('');
        checkEmail = false;
      }
    }
    if (value === 'password') {
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      !username ? checkUserName = true : checkUserName = false;
      !confirmPassword ? checkConfirmPass = true : checkConfirmPass = false;
      (password && confirmPassword !== password) ? checkPass = true : checkPass = false;
      confirm ? checkConfirm = false : checkConfirm = true;
      if (!password) {
        setPasswordErr('Vui lòng nhập mật khẩu');
        checkPass = true;
      } else {
        setPasswordErr('');
        checkPass = false;
      }
    }
    if (value === 'confirmPassword') {
      !username ? checkUserName = true : checkUserName = false;
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      !password ? checkPass = true : checkPass = false;
      confirm ? checkConfirm = false : checkConfirm = true;
      if (!confirmPassword) {
        setConfirmPasswordErr('Vui lòng xác nhận mật khẩu');
        checkConfirmPass = true;
      } else {
        if (confirmPassword !== password) {
          setConfirmPasswordErr('Mật khẩu không khớp');
          checkConfirmPass = true;
        } else {
          setConfirmPasswordErr('');
          checkConfirmPass = false;
        }
      }
    }
    if (confirm) {
      !username ? checkUserName = true : checkUserName = false;
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
      !password ? checkPass = true : checkPass = false;
      (!confirmPassword || confirmPassword !== password) ? checkConfirmPass = true : checkConfirmPass = false;
      checkConfirm = false;
    } else {
      checkConfirm = true;
    }
    (checkUserName || checkEmail || checkPass || checkConfirmPass || checkConfirm) ? setInvalid(true) : setInvalid(false);
  }

  const handleRegisterAccount = (token) => {
    setInvalid(true);
    setLoading(true);
    setAlertDanger(false);
    setDuplicateEmail(false);
    registerAccount(username, email, password, confirmPassword, 'Employee', token).then((res) => {
      if (res.status === 200) {
        login(email, password).then((result) => {
          if (result.accessToken) {
            setStorage(STORAGE_KEYS.ACCESS_TOKEN, result.accessToken);
            setStorage(STORAGE_KEYS.REFRESH_TOKEN, result.refreshToken);
            setLoading(false);
            navigate("/");
          } else {
            setLoading(false);
            setAlertDanger(true);
          }
        })
      } else {
        if (res.status === 400 && res.detail.indexOf("DuplicateEmail") > -1) {
          setLoading(false);
          setDuplicateEmail(true);
          setAlertDanger(false);
        } else {
          setLoading(false);
          setAlertDanger(true);
          setDuplicateEmail(false);
        }
      }
    })
  };

  const handleTermsOfService = () => {
    window.open('/termsofservice');
  };

  const handlePrivacyAndPolicy = () => {
    window.open('/privacyandpolicy');
  };

  const handleReCaptchaVerify = () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    executeRecaptcha('registerEmployee').then((token) => {
      handleRegisterAccount(token);
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="align-items-center">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={bannerImage}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={sigupImage}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={bannerImage}
                                alt=""
                                className="logo-dark w-100"
                              />
                              <img
                                src={sigupImage}
                                alt=""
                                className="logo-dark w-100"
                              />
                            </Link>

                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          {loading && <Spinners />}
                          <CardBody className="auth-content p-5 text-white">
                            <div className="w-100">
                              <div className="text-center">
                                <h5>{t("signup.welcome")}</h5>
                                <p className="text-white-70">
                                  {t("signup.emp.desc")}
                                </p>
                              </div>
                              <div className="auth-form">
                                <div className="mb-3">
                                  <label
                                    htmlFor="usernameInput"
                                    className="form-label"
                                  >
                                    {t("signup.tenungvien")}
                                  </label>
                                  <Input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    required
                                    id="usernameInput"
                                    placeholder={t("signup.nhaptenungvien")}
                                    onChange={handleUserName}
                                    value={username}
                                  />
                                  {usernameErr && <label style={{ color: 'red', fontSize: 14 }}>{usernameErr}</label>}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    {t("signup.email")}
                                  </label>
                                  <Input
                                    autoComplete="off"
                                    type="email"
                                    className="form-control"
                                    required
                                    id="emailInput"
                                    placeholder={t("signup.nhapemail")}
                                    onChange={handleEmail}
                                    value={email}
                                  />
                                  {emailErr && <label style={{ color: 'red', fontSize: 14 }}>{emailErr}</label>}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="emailInput"
                                    className="form-label"
                                  >
                                    {t("signup.matkhau")}
                                  </label>
                                  <Input
                                    type={passwordtype}
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder={t("signup.nhapmatkhau")}
                                    onChange={handlePassword}
                                    value={password}
                                  />
                                  <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10, color: 'black' }} onClick={handleShowPassword}>
                                    {passwordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                  </p>
                                  {passwordErr && <label style={{ color: 'red', fontSize: 14 }}>{passwordErr}</label>}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordReInput"
                                    className="form-label"
                                  >
                                    {t("signup.xacnhanmatkhau")}
                                  </label>
                                  <Input
                                    type={confirmpasswordtype}
                                    className="form-control"
                                    id="passwordReInput"
                                    placeholder={t("signup.xacnhanlaimatkhau")}
                                    onChange={handleConfirmPassword}
                                    value={confirmPassword}
                                  />
                                  <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10, color: 'black' }} onClick={handleShowConfirmPassword}>
                                    {confirmpasswordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                  </p>
                                  {confirmPasswordErr && <label style={{ color: 'red', fontSize: 14 }}>{confirmPasswordErr}</label>}
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    onChange={handleConfirm}
                                    value={confirm}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Tôi đã đọc và đồng ý với{" "}
                                    <Link
                                      onClick={handleTermsOfService}
                                      className="text-white form-text text-decoration-underline"
                                    >
                                      Điều khoản dịch vụ
                                    </Link>
                                    {" "} và {" "}
                                    <Link
                                      onClick={handlePrivacyAndPolicy}
                                      className="text-white form-text text-decoration-underline"
                                    >
                                      Chính sách bảo mật
                                    </Link>
                                  </label>
                                </div>
                              </div>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-white btn-hover w-100"
                                  onClick={handleReCaptchaVerify}
                                  disabled={invalid}
                                >
                                  {t("signup.dangky")}
                                </button>
                              </div>
                              <div className="mt-3 text-center">
                                <p className="mb-0">
                                  {t("signup.dacotaikhoan")}{" "}
                                  <Link
                                    to="/signin"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    {t("signup.dangnhap")}{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                      {alertdanger && <Alerts message={MESSAGE_VALIDATE.singup_invalid} />}
                      {duplicateEmail && <Alerts message={MESSAGE_VALIDATE.singup_duplicate_email} />}
                    </Card>
                  </Col>
                </Row>
                <ScrolltoTop />
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpEmployeeContent;
