export const jobTypeList = [
    { value: "2", label: "Tất cả hình thức" },
    { value: "0", label: "Toàn thời gian" },
    { value: "1", label: "Bán thời gian" },
];

export const jobTypeListFilter = [
    { value: "-1", label: "Hình thức làm việc" },
    { value: "-2", label: "Tất cả hình thức" },
    { value: "0", label: "Toàn thời gian" },
    { value: "1", label: "Bán thời gian" },
];

export const workingTypeList = [
    { value: "0", label: "Nhân viên chính thức" },
    { value: "1", label: "Thực tập sinh" },
    { value: "2", label: "Phái cử" },
];

export const genderList = [
    { value: "0", label: "Không muốn hiển thị" },
    { value: "1", label: "Nam" },
    { value: "2", label: "Nữ" },
    { value: "3", label: "Nam/Nữ" },
];

export const educationLevelList = [
    { value: "0", label: "Tiểu học" },
    { value: "1", label: "THCS" },
    { value: "2", label: "THPT" },
    { value: "3", label: "Trung cấp" },
    { value: "4", label: "Cao đẳng" },
    { value: "5", label: "Đại học" },
    { value: "6", label: "Cao học" },
];

export const candidateTypeList = [
    { value: "0", label: "Online" },
    { value: "1", label: "Trực tiếp" },
];

export const reviewSalaryList = [
    { value: "0", label: "1 lần/năm" },
    { value: "1", label: "2 lần/năm" },
    { value: "2", label: "3 lần/năm" },
];

export const experienceRequiredList = [
    { value: "0", label: "Không yêu cầu" },
    { value: "1", label: "1 năm trở lên" },
    { value: "2", label: "2 năm trở lên" },
    { value: "3", label: "3 năm trở lên" },
    { value: "4", label: "4 năm trở lên" },
    { value: "5", label: "5 năm trở lên" },
];

export const experienceRequiredListFilter = [
    { value: "-1", label: "Kinh nghiệm" },
    { value: "0", label: "Không yêu cầu" },
    { value: "1", label: "1 năm trở lên" },
    { value: "2", label: "2 năm trở lên" },
    { value: "3", label: "3 năm trở lên" },
    { value: "4", label: "4 năm trở lên" },
    { value: "5", label: "5 năm trở lên" },
];

export const positionList = [
    { value: "1", label: "Tổng giám đốc" },
    { value: "2", label: "Giám đốc" },
    { value: "3", label: "Phó giám đốc" },
    { value: "4", label: "Chủ tịch HĐQT" },
    { value: "5", label: "Trưởng phòng" },
    { value: "6", label: "Trưởng nhóm" },
    { value: "7", label: "Nhân viên" },
];

export const marriageList = [
    { value: "1", label: "Độc thân" },
    { value: "2", label: "Đã kết hôn" },
    { value: "3", label: "Đã ly hôn" },
];

export const certificateOfEligibilityList = [
    { value: "1", label: "Chưa từng" },
    { value: "2", label: "Đã từng" },
];

export const bloodTypeList = [
    { value: "1", label: "Không biết" },
    { value: "2", label: "A" },
    { value: "3", label: "B" },
    { value: "4", label: "AB" },
    { value: "5", label: "O" },
];

export const preferredHandList = [
    { value: "1", label: "Phải" },
    { value: "2", label: "Trái" },
];

export const eyesightList = [
    { value: "1", label: "1/10" },
    { value: "2", label: "2/10" },
    { value: "3", label: "3/10" },
    { value: "4", label: "4/10" },
    { value: "5", label: "5/10" },
    { value: "6", label: "6/10" },
    { value: "7", label: "7/10" },
    { value: "8", label: "8/10" },
    { value: "9", label: "9/10" },
    { value: "10", label: "10/10" },
];

export const favoriteSubjectList = [
    { value: "1", label: "Toán học" },
    { value: "2", label: "Văn học" },
    { value: "3", label: "Vật lý" },
    { value: "4", label: "Hóa học" },
    { value: "5", label: "Ngoại ngữ" },
    { value: "6", label: "Địa lý" },
    { value: "7", label: "Lịch sử" },
    { value: "8", label: "Thể dục" }, ,
];

export const personalityList = [
    { value: "1", label: "Thật thà, hiền lành" },
    { value: "2", label: "Nhiệt tình" },
    { value: "3", label: "Biết nỗ lực" },
    { value: "4", label: "Biết quan tâm, chăm sóc" },
    { value: "5", label: "Nghiêm túc, chăm chỉ" },
    { value: "6", label: "Mạnh mẽ" },
    { value: "7", label: "Mỏng manh, dễ vỡ" },
    { value: "8", label: "Nói nhiều" },
    { value: "9", label: "Hiền lành, ít nói" },
    { value: "10", label: "Bảo thủ" },
];

export const strongPointList = [
    { value: "1", label: "Khả năng chủ động" },
    { value: "2", label: "Lạc quan, tích cực" },
    { value: "3", label: "Hoạt bát" },
    { value: "4", label: "Hài hước" },
    { value: "5", label: "Thận trọng" },
];

export const weakpointList = [
    { value: "1", label: "Bi quan" },
    { value: "2", label: "Thiếu kiên nhẫn" },
    { value: "3", label: "Ngại ngùng khi gặp người lạ" },
    { value: "4", label: "Hay suy nghĩ" },
    { value: "5", label: "Nhút nhát" },
    { value: "6", label: "Tò mò" },
];

export const majorsList = [
    { value: "1", label: "Học sinh" },
    { value: "2", label: "Luật" },
    { value: "3", label: "Công nghệ" },
    { value: "4", label: "Ngôn ngữ" },
    { value: "5", label: "Kinh tế" },
    { value: "6", label: "Giáo dục" },
];

export const graduatestatusList = [
    { value: "1", label: "Tốt nghiệp" },
    { value: "2", label: "Đang học" },
    { value: "3", label: "Bảo lưu" },
];

export const visaTypeList = [
    { value: "1", label: "Visa du lịch" },
    { value: "2", label: "Visa thăm thân" },
    { value: "3", label: "Visa thương mại" },
    { value: "4", label: "Visa quá cảnh" },
    { value: "5", label: "Visa du học" },
    { value: "6", label: "Visa lao động" },
];

export const qualificationList = [
    { value: "0", label: "Không yêu cầu" },
    { value: "1", label: "Bằng lái xe số sàn" },
    { value: "2", label: "Bằng lái xe số tự động" },
    { value: "3", label: "Bằng lái xe máy" },
    { value: "4", label: "Chứng chỉ nghề xây dựng" },
    { value: "5", label: "Chứng chỉ nghề xe nâng" },
    { value: "6", label: "Chứng chỉ nghề may" },
    { value: "7", label: "Chứng chỉ nghề làm vườn" },
];

export const statusList = [
    { value: "0", label: "Đang hiển thị" },
    { value: "1", label: "Đang ẩn" },
];