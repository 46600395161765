
import React, { useState, useEffect } from "react";
import { Col, Row, Container, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import homepage from "../../assets/images/nhatuyendung/homepage.webp";
import step1 from "../../assets/images/nhatuyendung/step1.webp";
import step2 from "../../assets/images/nhatuyendung/step2.webp";
import step3 from "../../assets/images/nhatuyendung/step3.webp";
import step4 from "../../assets/images/nhatuyendung/step4.webp";
import step5 from "../../assets/images/nhatuyendung/step5.webp";
import description1 from "../../assets/images/howitwork/description1.webp";
import description2 from "../../assets/images/howitwork/description2.webp";
import description3 from "../../assets/images/howitwork/description3.webp";
import description4 from "../../assets/images/howitwork/description4.webp";
import description5 from "../../assets/images/howitwork/description5.webp";
import description6 from "../../assets/images/howitwork/description6.webp";
import register from "../../assets/images/nhatuyendung/register.webp";
import './AboutCompany.css';

const AboutCompany = () => {
    document.title = "TopJob360 - Tuyển dụng";
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    const handleRegister = () => {
        navigate("/signup/candidate");
    }

    return (
        <React.Fragment>
            <div className="aboutus">
                <div className="introduce" style={{ backgroundImage: `url(${homepage})` }}>
                </div>
                <Container>
                    <div data-aos="fade-down">
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <div className="text-center" style={{ marginLeft: '24px' }}>
                                    <div className="video-embed video-introduce">
                                        <iframe
                                            src="https://www.youtube.com/embed/YNfF1-wcd6A?si=z1sHl6Bm6o1kNsBN&rel=0"
                                            title="YouTube video player" frameborder="0" loading="lazy"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowfullScreen></iframe>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="company">
                        <div className="company-text">
                            <div data-aos="fade-down">
                                <div className="title-slogan">
                                    <p>{t("tuyendung.thuhutnhantai1")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("tuyendung.thuhutnhantai2")}</p>
                                </div>
                                <p>{t("tuyendung.bandangtimkiem1")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("tuyendung.bandangtimkiem2")}</p>
                                <p><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("tuyendung.nentangtuyendung")}</p>
                            </div>
                            <Container>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.tiepcannguonungvien")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungtiepcan")}</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description1} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description2} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.timkiemchinhxac")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungtimkiem")}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.tietkiemthoigian")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungtietkiem")}</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description3} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description4} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.danhgiaungvien")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungdanhgia")}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--right aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.quanlyquytrinh")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungquanly")}</p>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={description5} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="code code--small code--left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                                    <Row className="align-items-center">
                                        <Col lg={6}>
                                            <img src={description6} alt="" className="img-fluid" />
                                        </Col>
                                        <Col lg={6}>
                                            <h5 className="fs-18">{t("tuyendung.ngoaira1")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("tuyendung.ngoaira2")}</h5>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungngoaira1")}</p>
                                            </div>
                                            <div className="description-list">
                                                <p>{t("tuyendung.noidungngoaira2")}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="section-title me-5">
                            <h3 className="title">{t("ungvien.cachthuchoatdong")}</h3>
                            <Nav className="process-menu  flex-column nav-pills">
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">1</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("ungvien.taotaikhoan")}</h5>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.taotaikhoan1")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.taotaikhoan2")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.taotaikhoan3")}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">2</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("tuyendung.dangtintuyendung")}</h5>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.dangtintuyendung11")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("tuyendung.dangtintuyendung12")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.dangtintuyendung2")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.dangtintuyendung3")}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "3" })}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">3</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("tuyendung.timkiemungvien")}</h5>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.timkiemungvien1")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.timkiemungvien2")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.timkiemungvien3")}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "4" })}
                                    onClick={() => {
                                        tabChange("4");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">4</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("tuyendung.quanlyhoso")}</h5>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.quanlyhoso1")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.quanlyhoso2")}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>

                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "5" })}
                                    onClick={() => {
                                        tabChange("5");
                                    }}
                                    type="button"
                                >
                                    <div className="d-flex">
                                        <div className="number flex-shrink-0">5</div>
                                        <div className="flex-grow-1 text-start ms-3">
                                            <h5 className="fs-18">{t("tuyendung.tinhnangkhac")}</h5>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.tinhnangkhac1")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.tinhnangkhac2")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.tinhnangkhac3")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.tinhnangkhac4")}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t("tuyendung.tinhnangkhac5")}
                                            </p>
                                        </div>
                                    </div>
                                </NavLink>
                            </Nav>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <img src={step1} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="2">
                                <img src={step2} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="3">
                                <img src={step3} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="4">
                                <img src={step4} alt="" className="img-fluid" />
                            </TabPane>
                            <TabPane tabId="5">
                                <img src={step5} alt="" className="img-fluid" />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={12}>
                        <div className="section-title text-center" data-aos="fade-up">
                            <h3 className="title">Chúc bạn thành công trong việc tuyển dụng nhân tài với <span style={{ color: "#027F52" }}>TopJob360</span>!</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
            <button onClick={handleRegister}>
                <img src={register} alt="" className="button-register" />
            </button>
        </React.Fragment>
    )
}



export default AboutCompany;
