import React from "react";
//Company Section
import AboutUs from "../pages/ExtraPages/AboutUs";
import AboutCompany from "../pages/ExtraPages/AboutCompany";
import AboutCadidate from "../pages/ExtraPages/AboutCadidate";
import PrivacyAndPolicy from "../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy";
import TermsofUse from "../pages/Company/PrivacyAndPolicy/TermsofUse";

//Jobs Section
import JobList from "../pages/Jobs/JobList/JobList";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";

//Candidate and Company Section
import CompanyList from "../pages/CandidateAndCompany/CompanyList/CompanyList";
import CompanyDetails from "../pages/CandidateAndCompany/CompanyDetails/CompanyDetails";
import CompanyView from "../pages/CandidateAndCompany/CompanyView/CompanyView";

//AuthPages
import SignIn from "../pages/ExtraPages/SignIn";
import SignUp from "../pages/ExtraPages/SignUp";
import SignUpCandidate from "../pages/ExtraPages/SignUpCandidate/SignUpCandidate";
import SignUpEmployee from "../pages/ExtraPages/SignUpEmployee/SignUpEmployee";
import SignOut from "../pages/ExtraPages/SignOut";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import ResetPasswordNewPass from "../pages/ExtraPages/ResetPasswordNewPass";
import ChangePassword from "../pages/ExtraPages/ChangePassword";
import ComingSoon from "../pages/ExtraPages/ComingSoon";
import Error404 from "../pages/ExtraPages/Error404";
import ActiveEmail from "../pages/ExtraPages/ActiveEmail";

//profile section(User Profile)
import BookMarkJobPost from "../pages/Profile/BookMarkJobPost/BookMarkJobPost";
import ManageJobs from "../pages/Profile/ManageJobs/ManageJobs";
import ManageJobApply from "../pages/Profile/ManageJobApply/ManageJobApply";
import BookMarkJobs from "../pages/Profile/BookMarkJobs/BookMarkJobs";
import MyProfile from "../pages/Profile/MyProfile/MyProfile";
import CompanyProfile from "../pages/Company/Profile/CompanyProfile";
import Account from "../pages/Profile/MyProfile/Account";
import JobMyApplied from "../pages/Profile/MyProfile/JobMyApplied";
import AdminPage from "../pages/Admin/Main/AdminPage";
import AdminLogin from "../pages/Admin/Login/AdminLogin";

//Home Section
const Layout = React.lazy(() => import('../pages/Home/Layout/Layout'));

//Admin Section
const CategoryAdmin = React.lazy(() => import('../pages/Admin/Category/Category'));
const CountryAdmin = React.lazy(() => import('../pages/Admin/CountryCity/CountryCity'));

const userRoutes = [
  //profile Section(User Profile)
  { path: "/bookmarkjobpost", component: <BookMarkJobPost /> },
  { path: "/companyprofile", component: <CompanyProfile /> },
  { path: "/bookmarkjobs", component: <BookMarkJobs /> },

  //Candidate and Company Section
  { path: "/companylist", component: <CompanyList /> },

  //Jobs Section
  { path: "/joblist", component: <JobList /> },
  { path: "/joblist/:countryid/:industryid", component: <JobList /> },

  //Route for keyword
  { path: "/viec-lam-nha-hang-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nha-hang-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-khach-san-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-khach-san-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-che-bien-thuc-pham-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-che-bien-thuc-pham-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-xay-dung-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-xay-dung-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-dieu-duong-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dieu-duong-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-co-khi-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-co-khi-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-dien-tu-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-dien-tu-sip/:countryid/:industryid", component: <JobList /> },

  { path: "/viec-lam-nong-nghiep-nhat-ban/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-han-quoc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-duc/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-singapore/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-quatar/:countryid/:industryid", component: <JobList /> },
  { path: "/viec-lam-nong-nghiep-sip/:countryid/:industryid", component: <JobList /> },


  //Company Section
  { path: "/privacyandpolicy", component: <PrivacyAndPolicy /> },
  { path: "/termsofservice", component: <TermsofUse /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/nha-tuyen-dung", component: <AboutCompany /> },
  { path: "/ung-vien", component: <AboutCadidate /> },

  //Home Section
  { path: "/", component: <Layout /> },

  //ViewCompany
  { path: "/company/:username", component: <CompanyView /> },
  //ViewJob
  { path: "/job/:id", component: <JobDetails /> },
];

const adminRoutes=[
  {path: "/quanly", component: <AdminPage/>},
  {path: "/quanly/category", component: <CategoryAdmin/>},
  {path: "/quanly/country", component: <CountryAdmin/>},
];

const userRoleRoutes=[
  { path: "/myprofile", component: <MyProfile /> },
  { path: "/account", component: <Account /> },
  { path: "/myapplied", component: <JobMyApplied /> },
];

const adminRoleRoutes=[
  { path: "/companydetails", component: <CompanyDetails /> },
  { path: "/managejobs", component: <ManageJobs /> },
  { path: "/managejobapply", component: <ManageJobApply /> },
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/account/emailconfirm", component: <ActiveEmail /> },
  { path: "/comingsoon", component: <ComingSoon /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/account/lostpassword", component: <ResetPasswordNewPass /> },
  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/signout", component: <SignOut /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/signup/candidate", component: <SignUpCandidate /> },
  { path: "/signup/employee", component: <SignUpEmployee /> },
  { path: "/signin", component: <SignIn /> },
  { path: "/dang-nhap-admin", component: <AdminLogin /> },
  

];
export { userRoutes, authRoutes, adminRoutes, userRoleRoutes, adminRoleRoutes };