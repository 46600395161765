
import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import HoSoUngVien from "../../assets/images/nhatuyendung/step2.webp";
import ViecLamDaDang from "../../assets/images/nhatuyendung/step4.webp";
import TimKiemThongMinh from "../../assets/images/nhatuyendung/step3.webp";
import CongDong from "../../assets/images/nhatuyendung/step5.webp";
import Banner from "../../assets/images/about/banner.webp"
import './AboutCompany.css';

const AboutUs = () => {
    document.title = "TopJob360 - Giới thiệu";
    const { t } = useTranslation("translation");

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <React.Fragment>
            <div className="aboutus">
                <div className="introduce" style={{ backgroundImage: `url(${Banner})` }}>
                </div>
                <Container>
                    <div className="company">
                        <div data-aos="fade-down">
                            <div className="title-slogan">
                                <p><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.gioithieu")}</p>
                            </div>
                        </div>
                    </div>
                </Container>
                <section className="section bg-light">
                    <Container>
                        <Row className="justify-content-center" data-aos="fade-down">
                            <Col lg={6}>
                                <div className="section-title text-center mb-5">
                                    {localStorage.getItem("lng") == "vi" && <h5 className="title mb-3">{t("aboutus.sukhacbiet")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h5>}
                                    {localStorage.getItem("lng") == "jp" && <h5 className="title mb-3"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>{t("aboutus.sukhacbiet")}</h5>}
                                    {localStorage.getItem("lng") == "en" && <h5 className="title mb-3">{t("aboutus.sukhacbiet")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h5>}
                                    {localStorage.getItem("lng") == "gr" && <h5 className="title mb-3">{t("aboutus.sukhacbiet")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")} </span>aus</h5>}
                                    {localStorage.getItem("lng") == "kr" && <h5 className="title mb-3"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>{t("aboutus.sukhacbiet")}</h5>}
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center" data-aos="fade-down">
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={HoSoUngVien} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">{t("aboutus.hosoungvien")}</h5>
                                        </Link>
                                        <p className="text-muted"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.cungcapcongcu")}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={ViecLamDaDang} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">{t("aboutus.vieclamdadang")}</h5>
                                        </Link>
                                        {localStorage.getItem("lng") == "vi" && <p className="text-muted">{t("aboutus.hangnganvieclam")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>.</p>}
                                        {localStorage.getItem("lng") == "jp" && <p className="text-muted"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>{t("aboutus.hangnganvieclam")}</p>}
                                        {localStorage.getItem("lng") == "en" && <p className="text-muted">{t("aboutus.hangnganvieclam")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>.</p>}
                                        {localStorage.getItem("lng") == "gr" && <p className="text-muted">{t("aboutus.hangnganvieclam")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>aktualisiert.</p>}
                                        {localStorage.getItem("lng") == "kr" && <p className="text-muted">{t("aboutus.hangnganvieclam")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>에 지속적으로 업데이트됩니다.</p>}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={TimKiemThongMinh} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">{t("aboutus.timkiemthongminh")}</h5>
                                        </Link>
                                        <p className="text-muted">{t("aboutus.hethongtimkiem")}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={6}></Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={HoSoUngVien} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">{t("aboutus.ungtuyendedang")}</h5>
                                        </Link>
                                        <p className="text-muted">{t("aboutus.vaiclick")}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="blog-box card p-2 mt-3" style={{ height: '500px' }}>
                                    <div className="blog-img position-relative overflow-hidden">
                                        <img src={CongDong} alt="" className="img-fluid" />
                                        <div className="bg-overlay"></div>
                                    </div>
                                    <div className="card-body">
                                        <Link className="primary-link">
                                            <h5 className="fs-17">{t("aboutus.congdonglonmanh")}</h5>
                                        </Link>
                                        <p className="text-muted"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.congdongdongdao")}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2} md={6}></Col>
                        </Row>
                    </Container>
                </section>
                <section className="section overflow-hidden">
                    <Container>
                        <div data-aos="fade-down" data-aos-duration="1000">
                            <Row>
                                <div className="section-title text-center mb-5">
                                    {localStorage.getItem("lng") == "vi" && <h2 className="title mb-4">{t("aboutus.loiichsudung")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h2>}
                                    {localStorage.getItem("lng") == "jp" && <h2 className="title mb-4"><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>{t("aboutus.loiichsudung")}</h2>}
                                    {localStorage.getItem("lng") == "en" && <h2 className="title mb-4">{t("aboutus.loiichsudung")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h2>}
                                    {localStorage.getItem("lng") == "gr" && <h2 className="title mb-4">{t("aboutus.loiichsudung")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h2>}
                                    {localStorage.getItem("lng") == "kr" && <h2 className="title mb-4">{t("aboutus.loiichsudung")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span></h2>}
                                </div>
                                <Col lg={6}>
                                    <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                        <h5 className="title mb-2">{t("aboutus.doivoiungvien")}</h5>
                                        <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                                            <li> {t("aboutus.loiichungvien1")}</li>
                                            <li> {t("aboutus.loiichungvien2")}</li>
                                            <li> {t("aboutus.loiichungvien3")}</li>
                                            <li> {t("aboutus.loiichungvien4")}</li>
                                            <li> {t("aboutus.loiichungvien5")}</li>
                                            {localStorage.getItem("lng") == "vi" && <li> Cộng đồng <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.loiichungvien6")}</li>}
                                            {localStorage.getItem("lng") == "jp" && <li> 困ったときは海外に滞在している <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.loiichungvien6")}</li>}
                                            {localStorage.getItem("lng") == "en" && <li> The <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.loiichungvien6")}</li>}
                                            {localStorage.getItem("lng") == "gr" && <li> Die <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.loiichungvien6")}</li>}
                                            {localStorage.getItem("lng") == "kr" && <li> Cộng đồng <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("aboutus.loiichungvien6")}</li>}
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                        <h5 className="title mb-2">{t("aboutus.doivoinhatuyendung")}</h5>
                                        <ul className="list-unstyled about-list text-muted">
                                            <li> {t("aboutus.loiichtuyendung1")}</li>
                                            <li> {t("aboutus.loiichtuyendung2")}</li>
                                            <li> {t("aboutus.loiichtuyendung3")}</li>
                                            <li> {t("aboutus.loiichtuyendung4")}</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
                <Container>
                    <div data-aos="fade-down">
                        <div className="footer-about-slogan mb-5">
                            <h2>{t("aboutus.thegioironglon")}</h2>
                        </div>
                    </div>
                    <div data-aos="fade-up">
                        <div className="section-title text-center mb-5" style={{ color: '#027F52' }}>
                            <h5>{t("aboutus.bandasansang")}</h5>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AboutUs;
