import { Font, Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { certificateOfEligibilityList } from "../../../../../common/dropdownList/dropdownData";
import fontNotoSansRegular from "../../../../../assets/fonts/NotoSansRegular.otf";
import fontNotoSansRegularItalic from "../../../../../assets/fonts/NotoSansRegularItalic.otf";
import fontNotoSansBold from "../../../../../assets/fonts/NotoSansBold.otf";
import fontNotoSansBoldItalic from "../../../../../assets/fonts/NotoSansBoldItalic.otf";
import visa from "../../../../../assets/images/ungvien/visa.png";
import industry from "../../../../../assets/images/ungvien/industry_skill.png";
import education from "../../../../../assets/images/ungvien/education.png";
import work from "../../../../../assets/images/ungvien/work.png";

const TemplateBasic2 = (props) => {
    const { t } = useTranslation("translation");
    Font.register({ family: "NotoSansRegular", src: fontNotoSansRegular });
    Font.register({ family: "NotoSansRegularItalic", src: fontNotoSansRegularItalic });
    Font.register({ family: "NotoSansBold", src: fontNotoSansBold });
    Font.register({ family: "NotoSansBoldItalic", src: fontNotoSansBoldItalic });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF",
            fontFamily: "NotoSansRegular"
        },
        image: {
            height: 150,
            width: 150,
            borderRadius: "50%"
        },
        container: {
            display: "flex",
            flexDirection: "row",
            padding: 5,
            borderRadius: 30,
            backgroundColor: "#AFEEEE"
        },
        fullname: {
            paddingTop: 20,
            paddingLeft: 10,
            textAlign: "center",
            color: "#FFA500",
            fontSize: 28
        },
        othername: {
            paddingTop: 2,
            paddingLeft: 10,
            textAlign: "center",
            color: "#000000",
            fontSize: 20
        },
        boxContainer: {
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            padding: 5,
        },
        boxTitle: {
            height: 40,
            backgroundColor: "#FFFACD",
            zIndex: 2,
            marginTop: -20,
            borderRadius: 10,
            marginBottom: 20,
            justifyContent: "center"
        },
        texTitle: {
            fontSize: 12,
            textAlign: "center",
            textTransform: "uppercase",
            fontFamily: "NotoSansBold",
            color: "#FFA500"
        },
        leftContainerTitle: {
            backgroundColor: "#AFEEEE",
            padding: 5,
            width: "35%",
            flexDirection: "column",
            borderRadius: 30,
            marginRight: 10,
            marginTop: 30,
            zIndex: 1
        },
        leftContainer: {
            backgroundColor: "#AFEEEE",
            padding: 5,
            width: "35%",
            flexDirection: "column",
            borderRadius: 30,
            marginLeft: 10,
            marginTop: 30,
            zIndex: 1
        },
        rightContainer: {
            backgroundColor: "#AFEEEE",
            padding: 5,
            width: "65%",
            flexDirection: "column",
            borderRadius: 30,
            marginLeft: 30,
            marginTop: 30,
            zIndex: 1
        },
        contentBox: {
            paddingLeft: 10,
            fontSize: 11,
            color: "#000080"
        },
        boxTitleTimeLine: {
            height: 40,
            width: "40%",
            backgroundColor: "#AFEEEE",
            zIndex: 2,
            marginTop: -20,
            borderRadius: 10,
            marginBottom: 20,
            justifyContent: "center"
        },
        timeLineData: {
            padding: 5,
            width: "60%",
            flexDirection: "column",
            marginLeft: 10,
            marginTop: 30,
            zIndex: 1
        },
        imageIcon: {
            backgroundColor: "#AFEEEE",
            borderRadius: "50%",
            width: 25,
            height: 25,
            alignItems: "center",
            justifyContent: "center"
        }
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: "row" }}>
                    <View style={[styles.container, { marginTop: 20, marginLeft: 10, marginRight: 10, width: "100%" }]}>
                        <View style={{ width: "40%" }}>
                            <Image
                                style={styles.image}
                                source={props.data.avatar}
                            />
                        </View>
                        <View style={{ flexDirection: "column" }}>
                            <View style={styles.fullname}>
                                <Text style={{ fontFamily: "NotoSansBold" }}>{props.data.fullName}</Text>
                            </View>
                            <View style={styles.othername}>
                                <Text style={{ fontFamily: "NotoSansBold" }}>{props.data.otherName}</Text>
                            </View>
                            <View style={{ flexDirection: "row", paddingLeft: 2, width: "100%" }}>
                                <View style={{ flexDirection: "column", width: "50%" }}>
                                    <Text style={styles.contentBox}>{t("template.email")}: {props.data.email}</Text>
                                    <Text style={styles.contentBox}>{t("template.sodienthoai")}: {props.data.phone}</Text>
                                    <Text style={styles.contentBox}>{t("template.gioitinh")}: {props.data.gender}</Text>
                                </View>
                                <View style={{ flexDirection: "column", paddingLeft: 5, width: "50%" }}>
                                    <Text style={styles.contentBox}>{t("template.kethon")}: {props.data.marriage}</Text>
                                    <Text style={styles.contentBox}>{t("template.ngaysinh")}: {props.data.birthDay}</Text>
                                    <Text style={styles.contentBox}>{t("template.tuoi")}: {props.data.age}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.boxContainer}>
                    <View style={styles.leftContainer}>
                        <View style={[styles.boxTitle, { width: "70%" }]}>
                            <Text style={styles.texTitle}>{t("template.tinhtrangsuckhoe")}</Text>
                        </View>
                        <Text style={styles.contentBox}>{t("template.chieucao")}: {props.data.height}</Text>
                        <Text style={styles.contentBox}>{t("template.cannang")}: {props.data.weight}</Text>
                        <Text style={styles.contentBox}>{t("template.nhommau")}: {props.data.bloodType}</Text>
                        <Text style={styles.contentBox}>{t("template.taythuan")}: {props.data.handedness}</Text>
                        <Text style={styles.contentBox}>{t("template.thiluc")}: {props.data.eyesight}</Text>
                    </View>
                    <View style={styles.timeLineData}>
                        <View style={styles.boxTitleTimeLine}>
                            <Text style={styles.texTitle}>{t("template.quatrinhhoctap")}</Text>
                        </View>
                        {
                            props.data.userEducations.map((item, key) => {
                                return (
                                    <View style={{ paddingBottom: 5 }}>
                                        <View style={{ flexDirection: "row", marginTop: -15 }}>
                                            <View style={styles.imageIcon}>
                                                <Image style={{ width: 15, height: 15 }} source={education} />
                                            </View>
                                            <Text style={{
                                                fontSize: 11, color: "#FFA500", paddingTop: 5, paddingLeft: 5,
                                                fontFamily: "NotoSansBold"
                                            }}>
                                                <Text style={[styles.contentBox, { marginLeft: 5, fontFamily: "NotoSansBold" }]}>{item.typeSchool.label} </Text>
                                                {item.detail} | {item.enrollmentMonth} - {item.graduationMonth}
                                            </Text>
                                        </View>
                                        <View style={{ borderLeft: 4, borderLeftColor: "#FFFACD", marginLeft: 10, paddingBottom: 10, flexDirection: "row" }}>
                                            <View style={{ width: "40%" }}>
                                                <Text style={[styles.contentBox, { marginLeft: 5, fontFamily: "NotoSansBold" }]}>{item.major}</Text>
                                            </View>
                                            <View style={{ width: "60%" }}>
                                                <Text style={[styles.contentBox, { marginLeft: 20, fontFamily: "NotoSansBold" }]}>{item.graduateStatus.label}</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.boxContainer}>
                    <View style={styles.leftContainer}>
                        <View style={[styles.boxTitle, { width: "70%" }]}>
                            <Text style={styles.texTitle}>{t("template.kynang")}</Text>
                        </View>
                        <Text style={styles.contentBox}>{t("template.bangcap")}: {props.data.qualifications}</Text>
                        <Text style={styles.contentBox}>{t("template.tiengnhat")}: {props.data.japaneseLevel}</Text>
                        <Text style={styles.contentBox}>{t("template.tienganh")}: {props.data.englishLevel}</Text>
                        <Text style={styles.contentBox}>{t("template.tiengkhac")}: {props.data.otherLevel}</Text>
                    </View>
                    <View style={styles.timeLineData}>
                        <View style={styles.boxTitleTimeLine}>
                            <Text style={styles.texTitle}>{t("template.quatrinhlamviec")}</Text>
                        </View>
                        {
                            props.data.userWorkExps.map((item, key) => {
                                return (
                                    <View style={{ paddingBottom: 5 }}>
                                        <View style={{ flexDirection: "row", marginTop: -15 }}>
                                            <View style={styles.imageIcon}>
                                                <Image style={{ width: 15, height: 15 }} source={work} />
                                            </View>
                                            <Text style={{
                                                fontSize: 11, color: "#FFA500", paddingTop: 5, paddingLeft: 5,
                                                fontFamily: "NotoSansBold"
                                            }}>
                                                {item.company} | {item.startMonth} - {item.endMonth}
                                            </Text>
                                        </View>
                                        <View style={{ borderLeft: 4, borderLeftColor: "#FFFACD", marginLeft: 10, paddingBottom: 10, flexDirection: "row" }}>
                                            <View style={{ width: "40%" }}>
                                                <Text style={[styles.contentBox, { marginLeft: 5, fontFamily: "NotoSansBold" }]}>{item.position.label}</Text>
                                            </View>
                                            <View style={{ width: "60%" }}>
                                                <Text style={[styles.contentBox, { marginLeft: 20 }]}>{item.jobDesc}</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.boxContainer}>
                    <View style={styles.leftContainer}>
                        <View style={[styles.boxTitle, { width: "70%" }]}>
                            <Text style={styles.texTitle}>{t("template.sothichtinhcach")}</Text>
                        </View>
                        <Text style={styles.contentBox}>{t("template.tinhcach")}: {props.data.personality}</Text>
                        <Text style={styles.contentBox}>{t("template.monhocsotruong")}: {props.data.favoriteSubjects}</Text>
                        <Text style={styles.contentBox}>{t("template.sotruong")}: {props.data.strongPoints}</Text>
                        <Text style={styles.contentBox}>{t("template.sodoan")}: {props.data.weakPoints}</Text>
                    </View>
                    <View style={styles.timeLineData}>
                        <View style={styles.boxTitleTimeLine}>
                            <Text style={styles.texTitle}>{t("template.kynangnganhnghe")}</Text>
                        </View>
                        {
                            props.data.userSkills.map((item, key) => {
                                return (
                                    <View style={{ paddingBottom: 5 }}>
                                        <View style={{ flexDirection: "row", marginTop: -15 }}>
                                            <View style={styles.imageIcon}>
                                                <Image style={{ width: 15, height: 15 }} source={industry} />
                                            </View>
                                            <Text style={{
                                                fontSize: 11, color: "#FFA500", paddingTop: 5, paddingLeft: 5,
                                                fontFamily: "NotoSansBold"
                                            }}>
                                                {item.labelIndustry}
                                            </Text>
                                        </View>
                                        <View style={{ borderLeft: 4, borderLeftColor: "#FFFACD", marginLeft: 10, paddingBottom: 10 }}>
                                            <Text style={[styles.contentBox, { marginLeft: 5, fontFamily: "NotoSansBold" }]}>{item.labelField}</Text>
                                            <Text style={[styles.contentBox, { marginLeft: 5 }]}>{item.labelSkill}</Text>
                                        </View>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.boxContainer}>
                    <View style={styles.leftContainer}>
                        <View style={[styles.boxTitle, { width: "70%" }]}>
                            <Text style={styles.texTitle}>{t("template.motabanthan")}</Text>
                        </View>
                        <Text style={styles.contentBox}>{props.data.yourself}</Text>
                    </View>
                    <View style={styles.timeLineData}>
                        <View style={styles.boxTitleTimeLine}>
                            <Text style={styles.texTitle}>{t("template.lichsuxinvisa")}</Text>
                        </View>
                        {
                            props.data.certificateOfEligibility.value != 2 && <View style={{ paddingBottom: 5 }}>
                                <View style={{ flexDirection: "row", marginTop: -15 }}>
                                    <Text style={{
                                        fontSize: 11, color: "#FFA500", paddingTop: 5, paddingLeft: 5,
                                        fontFamily: "NotoSansBold"
                                    }}>
                                        {certificateOfEligibilityList[0].label}
                                    </Text>
                                </View>
                            </View>
                        }
                        {
                            props.data.certificateOfEligibility.value == 2 && props.data.visaHistories.map((item, key) => {
                                return (
                                    <View style={{ paddingBottom: 5 }}>
                                        <View style={{ flexDirection: "row", marginTop: -15 }}>
                                            <View style={styles.imageIcon}>
                                                <Image style={{ width: 15, height: 15 }} source={visa} />
                                            </View>
                                            <Text style={{
                                                fontSize: 11, color: "#000080", paddingTop: 5, paddingLeft: 5,
                                                fontFamily: "NotoSansBold"
                                            }}>
                                                {item.country}
                                            </Text>
                                        </View>
                                        <View style={{ borderLeft: 4, borderLeftColor: "#FFFACD", marginLeft: 10, paddingBottom: 10, flexDirection: "row" }}>
                                            <View style={{ width: "40%" }}>
                                                <Text style={{ fontSize: 11, color: "#FFA500", marginLeft: 5, fontFamily: "NotoSansBold" }}>{item.visaType}</Text>
                                            </View>
                                            <View style={{ width: "60%" }}>
                                                <Text style={[styles.contentBox, { marginLeft: 20 }]}>{item.visaFromDate} - {item.visaExpDate}</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default TemplateBasic2;