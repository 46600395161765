import NhaHang from "../../assets/images/industry/nhahang.webp";
import KhachSan from "../../assets/images/industry/khachsan.webp";
import CheBienThucPham from "../../assets/images/industry/chebienthucpham.webp";
import XayDung from "../../assets/images/industry/xaydung.webp";
import DieuDuong from "../../assets/images/industry/dieuduong.webp";
import CoKhi from "../../assets/images/industry/cokhi.webp";
import DienTu from "../../assets/images/industry/diendientu.webp";
import NongNghiep from "../../assets/images/industry/nongnghiep.webp";
import ImageDefault from "../../assets/images/featured-job/img-default.webp";

let categories = [
    { id: 1401, image: NhaHang },
    { id: 2607, image: KhachSan },
    { id: 2348, image: CheBienThucPham },
    { id: 2496, image: XayDung },
    { id: 2570, image: DieuDuong },
    { id: 465, image: CoKhi },
    { id: 745, image: DienTu },
    { id: 1898, image: NongNghiep }
];

export const checkIconIndustry = (catId) => {
    let industryImage = categories.filter(c => c.id == catId);
    return industryImage.length ? industryImage[0].image : ImageDefault;
}