import React from "react";
import { Font, Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { certificateOfEligibilityList } from "../../../../../common/dropdownList/dropdownData";
import fontNotoSansRegular from "../../../../../assets/fonts/NotoSansRegular.otf";
import fontNotoSansRegularItalic from "../../../../../assets/fonts/NotoSansRegularItalic.otf";
import fontNotoSansBold from "../../../../../assets/fonts/NotoSansBold.otf";
import fontNotoSansBoldItalic from "../../../../../assets/fonts/NotoSansBoldItalic.otf";
import Moment from 'moment';

const TemplateBasic3 = (props) => {

    Font.register({ family: "NotoSansRegular", src: fontNotoSansRegular });
    Font.register({ family: "NotoSansRegularItalic", src: fontNotoSansRegularItalic });
    Font.register({ family: "NotoSansBold", src: fontNotoSansBold });
    Font.register({ family: "NotoSansBoldItalic", src: fontNotoSansBoldItalic });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF"
        },
        image: {
            height: 100,
            width: 80,
        },
        textTitle: {
            paddingLeft: 5,
            fontFamily: "NotoSansRegular",
            fontSize: 12
        },
        container: {
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            padding: 5,
        },
        boxTitle: {
            width: "50%",
            height: 40,
            backgroundColor: "#FFDEAD",
            zIndex: 2,
            marginTop: -20,
            borderRadius: 10,
            marginBottom: 20,
            justifyContent: "center"
        },
        texTitle: {
            fontSize: 12,
            textAlign: "center"
        },
        leftContainer: {
            backgroundColor: "#AFEEEE",
            padding: 5,
            width: "30%",
            flexDirection: "column",
            borderRadius: 30,
            marginLeft: 10,
            marginTop: 30,
            zIndex: 1
        },
        rightContainer: {
            backgroundColor: "#AFEEEE",
            padding: 5,
            width: "60%",
            flexDirection: "column",
            borderRadius: 30,
            marginLeft: 30,
            marginTop: 30,
            zIndex: 1
        },
        education: {
            padding: 5,
            width: "60%",
            flexDirection: "column",
            borderRadius: 30,
            marginLeft: 30,
            marginTop: 30,
            zIndex: 1
        },
        contentBox: {
            paddingLeft: 20
        }
    });

    let year = Moment(new Date).format("YYYY") + "年";
    let month = Moment(new Date).format("MM") + "月";
    let day = Moment(new Date).format("DD") + "日";
    let datecreate = year + month + day;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ flexDirection: "column", width: "700%" }}>
                        <View style={{ flexDirection: "row", padding: 20, width: "75%" }}>
                            <View style={{ width: "50%" }}>
                                <Text style={{ fontFamily: "NotoSansBold" }}>履 歴 書</Text>
                            </View>
                            <View style={{ width: "50%", alignItems: "flex-end" }}>
                                <Text style={{ fontFamily: "NotoSansBold" }}>{datecreate}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "column", width: "70%", borderLeft: 2, borderRight: 2, borderTop: 2, marginLeft: 10 }}>
                            <View style={{ width: "100%", flexDirection: "row" }}>
                                <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", borderBottom: 2, borderBottomStyle: "dotted" }}>
                                    <Text style={styles.textTitle}>フリガナ</Text>
                                </View>
                                <View style={{ width: "80%", borderBottom: 2, borderBottomStyle: "dotted" }}>
                                    <Text style={styles.textTitle}></Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", flexDirection: "row" }}>
                                <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", justifyContent: "center", alignItems: "center" }}>
                                    <Text style={styles.textTitle}>氏名</Text>
                                </View>
                                <View style={{ width: "80%", height: 61, justifyContent: "center", alignItems: "center" }}>
                                    <Text style={styles.textTitle}>{props.data.fullName}</Text>
                                </View>
                            </View>
                            <View style={{ width: "100.5%", flexDirection: "row", borderTop: 2 }}>
                                <View style={{ width: "80%", borderRight: 2 }}>
                                    <Text style={styles.textTitle}>生年月日: {props.data.birthDay}</Text>
                                </View>
                                <View style={{ width: "20.5%", height: 31 }}>
                                    <Text style={styles.textTitle}>Sex: {props.data.gender}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Image
                            style={{ height: 150, width: 130, marginLeft: -155, marginTop: 20 }}
                            source={props.data.avatar}
                        />
                    </View>
                </View>

                <View style={{ flexDirection: "column", width: "97%", border: 2, marginLeft: 10, marginTop: -3 }}>
                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ width: "57.5%", borderRight: 2, justifyContent: "center", alignItems: "center" }}>
                            <Text style={styles.textTitle}>現住所</Text>
                        </View>
                        <View style={{ width: "40%" }}>
                            <Text style={styles.textTitle}>TEL: {props.data.phone}</Text>
                        </View>
                    </View>
                    <View style={{ width: "100%", flexDirection: "row", borderTop: 2 }}>
                        <View style={{ width: "57.5%", borderRight: 2 }}>
                            <Text style={styles.textTitle}>{props.data.permanentAddress}</Text>
                        </View>
                        <View style={{ width: "40%", height: 50 }}>
                            <Text style={styles.textTitle}>EMAIL: {props.data.email}</Text>
                        </View>
                    </View>
                    <View style={{ width: "100%", flexDirection: "row", borderTop: 2 }}>
                        <View style={{ width: "57.5%", borderRight: 2 }}>
                            <Text style={styles.textTitle}>その他連絡先</Text>
                        </View>
                        <View style={{ width: "40%", flexDirection: "row" }}>
                            <View style={{ width: "37%", borderRight: 2 }}>
                                <Text style={styles.textTitle}>出身</Text>
                            </View>
                            <View style={{ width: "63%" }}>
                                <Text style={styles.textTitle}>希望勤務先</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: "100%", flexDirection: "row", borderTop: 2 }}>
                        <View style={{ width: "57.5%", borderRight: 2, height: 50 }}>
                            <Text style={styles.textTitle}></Text>
                        </View>
                        <View style={{ width: "40%", flexDirection: "row" }}>
                            <View style={{ width: "37%", borderRight: 2 }}>
                                <Text style={styles.textTitle}></Text>
                            </View>
                            <View style={{ width: "63%" }}>
                                <Text style={styles.textTitle}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ flexDirection: "column", width: "100%", border: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={styles.textTitle}>年</Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={styles.textTitle}>月</Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={styles.textTitle}>学歴</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ flexDirection: "column", width: "100%", border: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={styles.textTitle}>年</Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={styles.textTitle}>月</Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={styles.textTitle}>職歴</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ flexDirection: "column", width: "100%", border: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={styles.textTitle}>年</Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={styles.textTitle}>月</Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={styles.textTitle}>免許・資格</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "20%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center", height: 30 }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "10%", borderRight: 2, borderRightStyle: "dotted", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ width: "70%", alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ flexDirection: "column", width: "100%", border: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ width: "70%", borderRight: 2, alignItems: "center" }}>
                                <Text style={styles.textTitle}>志望の動機、特技、自己PRなど</Text>
                            </View>
                            <View style={{ width: "30%" }}>
                                <Text style={styles.textTitle}>配偶者</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: "row", borderTop: 2 }}>
                            <View style={{ width: "70%", borderRight: 2, alignItems: "center" }}>
                                <Text style={{ paddingLeft: 5 }}></Text>
                            </View>
                            <View style={{ flexDirection: "column", width: "30%" }}>
                                <View style={{ width: "100%", alignItems: "center", height: 29 }}>
                                    <Text style={styles.textTitle}></Text>
                                </View>
                                <View style={{ width: "100%", borderTop: 2, height: 29 }}>
                                    <Text style={styles.textTitle}>扶養家族</Text>
                                </View>
                                <View style={{ width: "100%", borderTop: 2, height: 29 }}>
                                    <Text style={styles.textTitle}>※配偶者を除く</Text>
                                </View>
                                <View style={{ width: "100%", alignItems: "center", height: 29 }}>
                                    <Text style={styles.textTitle}></Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ flexDirection: "column", width: "100%", border: 2 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ alignItems: "center" }}>
                                <Text style={styles.textTitle}>本人希望記入欄（特に給料、職種、勤務時間、勤務地、その他についての希望などがあれば記入）</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", borderTop: 2, height: 50 }}>
                            <View>
                                <Text style={styles.textTitle}></Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default TemplateBasic3;