import React, { useState } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
//Process Images Import
import description1 from "../../assets/images/nhatuyendung/step1.webp";
import description2 from "../../assets/images/ungvien/description2.webp";
import description3 from "../../assets/images/ungvien/description3.webp";
import description4 from "../../assets/images/ungvien/description4.webp";

const HowItWorks = () => {
  const { t } = useTranslation("translation");

  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="section-title me-5">
                <h3 className="title">{t("ungvien.cachthuchoatdong")}</h3>
                <Nav className="process-menu  flex-column nav-pills">
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      tabChange("1");
                    }}
                    type="button"
                  >
                    <div className="d-flex">
                      <div className="number flex-shrink-0">1</div>
                      <div className="flex-grow-1 text-start ms-3">
                        <h5 className="fs-18">{t("ungvien.taotaikhoan")}</h5>
                        <p className="text-muted mb-0">
                          {t("ungvien.taotaikhoan1")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.taotaikhoan2")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.taotaikhoan3")}
                        </p>
                      </div>
                    </div>
                  </NavLink>

                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      tabChange("2");
                    }}
                    type="button"
                  >
                    <div className="d-flex">
                      <div className="number flex-shrink-0">2</div>
                      <div className="flex-grow-1 text-start ms-3">
                        <h5 className="fs-18">{t("ungvien.hoanthienhoso")}</h5>
                        <p className="text-muted mb-0">
                          {t("ungvien.hoanthienhoso1")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.hoanthienhoso2")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.hoanthienhoso3")}
                        </p>
                      </div>
                    </div>
                  </NavLink>

                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      tabChange("3");
                    }}
                    type="button"
                  >
                    <div className=" d-flex">
                      <div className="number flex-shrink-0">3</div>
                      <div className="flex-grow-1 text-start ms-3">
                        <h5 className="fs-18">{t("ungvien.timkiemvieclam")}</h5>
                        <p className="text-muted mb-0">
                          {t("ungvien.timkiemvieclam1")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.timkiemvieclam2")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.timkiemvieclam3")}
                        </p>
                      </div>
                    </div>
                  </NavLink>

                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      tabChange("4");
                    }}
                    type="button"
                  >
                    <div className=" d-flex">
                      <div className="number flex-shrink-0">4</div>
                      <div className="flex-grow-1 text-start ms-3">
                        <h5 className="fs-18">{t("ungvien.ungtuyen")}</h5>
                        <p className="text-muted mb-0">
                          {t("ungvien.ungtuyen1")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.ungtuyen2")}
                        </p>
                        <p className="text-muted mb-0">
                          {t("ungvien.ungtuyen3")}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </Nav>
              </div>
            </Col>
            <Col lg={6}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <img src={description1} alt="" className="img-fluid" />
                </TabPane>
                <TabPane tabId="2">
                  <img src={description2} alt="" className="img-fluid" />
                </TabPane>
                <TabPane tabId="3">
                  <img src={description3} alt="" className="img-fluid" />
                </TabPane>
                <TabPane tabId="4">
                  <img src={description4} alt="" className="img-fluid" />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HowItWorks;
