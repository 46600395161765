import React, { useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import homepage from "../../../assets/images/ungvien/homepage.webp";
import step1 from "../../../assets/images/ungvien/step1.webp";
import step2 from "../../../assets/images/ungvien/step2.webp";
import step3 from "../../../assets/images/ungvien/step3.webp";
import step4 from "../../../assets/images/ungvien/step4.webp";
import step5 from "../../../assets/images/ungvien/step5.webp";
import step6 from "../../../assets/images/ungvien/step6.webp";
import extension1 from "../../../assets/images/ungvien/extension1.webp";
import extension2 from "../../../assets/images/ungvien/extension2.webp";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import '../../ExtraPages/AboutCompany.css';

const SectionEmployee = () => {
  document.title = "TopJob360 - Ứng viên";
  const { t } = useTranslation("translation");

  const keyFeatures = [
    {
      id: 1,
      title: t("ungvien.vieclamdadang"),
      content: t("ungvien.vieclamdadangnoidung"),
      imageFeatures: step1
    },
    {
      id: 2,
      title: t("ungvien.timkiemdedang"),
      content: t("ungvien.timkiemdedangnoidung"),
      imageFeatures: step2
    },
    {
      id: 3,
      title: t("ungvien.hosoantuong"),
      content: t("ungvien.hosoantuongnoidung"),
      imageFeatures: step3
    },
    {
      id: 4,
      title: t("ungvien.capnhatthuongxuyen"),
      content: t("ungvien.capnhatthuongxuyennoidung"),
      imageFeatures: step4
    },
    {
      id: 5,
      title: t("ungvien.hotrotantinh"),
      content: t("ungvien.hotrotantinhnoidung"),
      imageFeatures: step5
    },
    {
      id: 6,
      title: t("ungvien.hotroxinvisa"),
      content: t("ungvien.hotroxinvisanoidung"),
      imageFeatures: step6
    },
  ];

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <React.Fragment>
      <div className="aboutus">
        <div className="introduce" style={{ backgroundImage: `url(${homepage})` }}>
        </div>
        <Container>
          <div data-aos="fade-down">
            <div className="footer-about-slogan mb-5 mt-5">
              <h2>{t("ungvien.cohoivieclam")}</h2>
            </div>
          </div>
          <div data-aos="fade-left">
            <h5 className="text-center mb-3 title-candidate">{t("ungvien.banmuontimviec")}</h5>
          </div>
          <div data-aos="fade-right">
            <h5 className="text-center mb-3 title-candidate">{t("ungvien.banapuuocmo")}</h5>
          </div>
          <div data-aos="fade-left">
            <h5 className="text-center mb-3 title-candidate">{t("ungvien.banmongmuontrainghiem")}</h5>
          </div>
          <div data-aos="fade-up">
            <div className="footer-about-slogan mt-5">
              <h2>{t("ungvien.danhchoban")}</h2>
            </div>
          </div>
          <div data-aos="fade-down">
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="text-center" style={{ marginLeft: '24px' }}>
                  <div className="video-embed video-introduce">
                    <iframe
                      src="https://www.youtube.com/embed/2gRbn1u5Wis?si=wATcV2C8eQDPpm53&rel=0"
                      title="YouTube video player" loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up">
            <h5 className="text-center mb-3 title-candidate">{t("ungvien.congthongtin")}</h5>
          </div>
          <div data-aos="fade-up">
            <h5 className="text-center mb-3 title-candidate">{t("ungvien.cungcapvitrituyendung")}</h5>
          </div>
          <section className="section bg-light">
            <Container>
              <Row className="justify-content-center" data-aos="fade-down">
                <Col lg={6}>
                  <div className="section-title text-center mb-5">
                    {localStorage.getItem("lng") == "vi" && <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>{t("ungvien.taisaochon")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>?</h5>}
                    {localStorage.getItem("lng") == "jp" && <h5 className="text-center mb-3" style={{ fontSize: '24px' }}><span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>{t("ungvien.taisaochon")}?</h5>}
                    {localStorage.getItem("lng") == "en" && <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>{t("ungvien.taisaochon")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>?</h5>}
                    {localStorage.getItem("lng") == "gr" && <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>{t("ungvien.taisaochon")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>?</h5>}
                    {localStorage.getItem("lng") == "kr" && <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>{t("ungvien.taisaochon")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span>?</h5>}
                  </div>
                </Col>
              </Row>
              {
                keyFeatures.map((features, key) => (
                  <div className="job-box card mt-2" data-aos="fade-down" key={key}>
                    <Row className="align-items-center">
                      <Col lg={6}>
                        <img src={features.imageFeatures} alt="" className="img-fluid" />
                      </Col>
                      <Col lg={6}>
                        <h5 className="fs-18">{features.title}</h5>
                        <div className="description-list">
                          <p>{features.content}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              }
              <Row className="justify-content-center" data-aos="fade-up">
                <Col lg={6}>
                  <div className="section-title text-center mt-5">
                    <h5 className="text-center mb-3" style={{ fontSize: '24px' }}>{t("ungvien.gianhapcongdong1")} <span className="title-candidate">{t("aboutus.topjob360")}</span> {t("ungvien.gianhapcongdong2")}</h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <div data-aos="fade-up">
            <h5 className="text-center mt-3 mb-3" style={{ fontSize: '24px' }}>{t("ungvien.truycapwebsite1")} <span className="title-candidate">{t("ungvien.webtopjob")}</span> {t("ungvien.truycapwebsite2")}</h5>
          </div>
          <section className="section bg-light">
            <Container>
              <div className="section-title me-5" data-aos="fade-right">
                <h5>{t("ungvien.ngoailoiichtren")} <span style={{ color: "#027F52" }}>{t("aboutus.topjob360")}</span> {t("ungvien.mangdenchoban")}</h5>
              </div>
              <Row className="align-items-center" data-aos="fade-up">
                <Col lg={1} md={6}></Col>
                <Col lg={5} md={6}>
                  <div className="blog-box card p-2 mt-3" style={{ height: '400px' }}>
                    <div className="blog-img position-relative overflow-hidden">
                      <img src={extension1} alt="" className="img-fluid" />
                      <div className="bg-overlay"></div>
                    </div>
                    <div className="card-body">
                      <Link className="primary-link">
                        <h5 className="fs-17">{t("ungvien.loiich1")}</h5>
                      </Link>
                      <p className="text-muted">{t("ungvien.noidungloiich1")}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={6}>
                  <div className="blog-box card p-2 mt-3" style={{ height: '400px' }}>
                    <div className="blog-img position-relative overflow-hidden">
                      <img src={extension2} alt="" className="img-fluid" />
                      <div className="bg-overlay"></div>
                    </div>
                    <div className="card-body">
                      <Link className="primary-link">
                        <h5 className="fs-17">{t("ungvien.loiich2")}</h5>
                      </Link>
                      <p className="text-muted">{t("ungvien.noidungloiich2")}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={1} md={6}></Col>
              </Row>
            </Container>
          </section>
          <div data-aos="fade-up">
            <h5 className="text-center mt-5 title-candidate">{t("ungvien.thegioironglon")}</h5>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SectionEmployee;