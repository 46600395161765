import Moment from 'moment';

export const checkIconJobPost = (date_needed) => {
    let jobPost = "";
    if (date_needed) {
        let dateNow = new Date();
        let dateNeeded = new Date(date_needed);
        let postTime = dateNeeded.getTime() - dateNow.getTime();
        let days = Math.floor(postTime / (1000 * 60 * 60 * 24));
        if (Moment(new Date).format("YYYYMMDD") > Moment(date_needed).format("YYYYMMDD")) {
            jobPost = 6;
        } else {
            (days <= 7) ? (jobPost = 2) : (jobPost = 6);
        }
        return jobPost;
    }
    else {
        return "";
    }
}