import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { getAppliedJob } from "../../../api/apiJob";
import { checkIconIndustry } from "../../../common/checkValidate/checkIconIndustry";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import Pagination from "../../Jobs/Pagination/Pagination";
//Images Import
import userImage2 from "../../../assets/images/user/img-02.jpg";
import ModalUserInfo from "../../../common/modal/ModalUserInfo";

const ManageJobApplyList = (props) => {

    const jobDetail = "/job/";
    let [jobAppliedList, setJobAppliedList] = useState([]);
    let [alertNoData, setAlertNoData] = useState('');
    let [totalPage, setTotalPage] = useState(1);
    let [employee, setEmployee] = useState();
    const [modalViewProfile, setModalViewProfile] = useState(false);
    const openModalViewProfile = (user) => {
        setEmployee(user);
        setModalViewProfile(!modalViewProfile);
    }

    useEffect(() => {
        if (props.countryData) {
            loadAppliedJobData(1);
        }
    }, [props.countryData])

    const loadAppliedJobData = (page) => {
        const job = [];
        getAppliedJob(page).then((res) => {
            if (res.data) {
                setTotalPage(res.total);
                res.data.map((item, key) => {
                    job.push({
                        id: item.job.id,
                        avatar: item.employee.avatar ? item.employee.avatar : userImage2,
                        fullName: item.employee.fullName,
                        companyImg: item.job.image ? item.job.image : checkIconIndustry(item.job.catId),
                        jobTitle: item.job.title,
                        companyName: item.job.company,
                        files: getFileName(item.apply.files),
                        employee: item.employee
                    });
                });
                setJobAppliedList(job);
                if (job.length == 0) {
                    setAlertNoData('Không có kết quả tìm kiếm!');
                } else {
                    setAlertNoData('');
                }
            } else {
                setAlertNoData('Không có kết quả tìm kiếm!');
                setJobAppliedList([]);
            }
        })
    }

    const getFileName = (file) => {
        let fileName = file.split(",");
        let fileDescription = "";
        fileName.map((item, key) => {
            if (item == 1) {
                fileDescription += "CV, ";
            } if (item == 2) {
                fileDescription += "Bằng TN cấp cuối cùng, ";
            } if (item == 3) {
                fileDescription += "Bảng điểm TN cấp cuối cùng, ";
            } if (item == 4) {
                fileDescription += "Chứng chỉ ngoại ngữ cao nhất, ";
            } if (item == 5) {
                fileDescription += "Hộ chiếu, ";
            } if (item == 6) {
                fileDescription += "Visa hiện tại hoặc thẻ cư trú, ";
            } if (item == 7) {
                fileDescription += "Bằng lái xe, ";
            } if (item == 8) {
                fileDescription += "Chứng chỉ tiếng Anh, ";
            } if (item == 9) {
                fileDescription += "Chứng chỉ tiếng Nhật, ";
            } if (item == 10) {
                fileDescription += "Chứng chỉ tiếng Hàn, ";
            }
        })
        fileDescription = fileDescription.substring(0, fileDescription.lastIndexOf(","));
        return fileDescription;
    }

    const updateCurrentPage = (page) => {
        loadAppliedJobData(page);
    }

    return (
        <React.Fragment>
            {modalViewProfile && <ModalUserInfo employee={employee} countryData={props.countryData} modalState={modalViewProfile} toggleModal={openModalViewProfile} />}
            <Row className="align-items-center">
                <div>
                    {alertNoData && <div className="section-title text-center">
                        <h3 className="title">{alertNoData}</h3>
                    </div>}
                    <div className="mt-0">
                        <div className="p-4">
                            <Row className="align-items-center">
                                <Col md={5} className="text-center">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark">
                                                Ứng viên
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                                <Col md={5} className="text-center">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark" >
                                                Công việc
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                                <Col md={2} className="text-center">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="fs-14 mb-1">
                                            <Link className="text-dark">
                                                File đã ứng tuyển
                                            </Link>
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {(!jobAppliedList.length && !alertNoData) ? (<PlaceholderJobFilter />) : jobAppliedList && jobAppliedList.map((jobListDetails, key) => (
                        <div key={key} className="job-box card mt-4">
                            <div className="p-4">
                                <Row className="align-items-center">
                                    <Col md={2} style={{ textAlign: "right" }}>
                                        <div className="mb-4 mb-md-0">
                                            <Link>
                                                <img
                                                    src={jobListDetails.avatar}
                                                    alt=""
                                                    className="img-fluid rounded-3"
                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ textAlign: "left" }}>
                                        <div className="mb-2 mb-md-0">
                                            <h5 className="fs-18 mb-1">
                                                <Link onClick={() => openModalViewProfile(jobListDetails.employee)} className="text-dark">
                                                    {jobListDetails.fullName}
                                                </Link>
                                            </h5>
                                        </div>
                                    </Col>

                                    <Col md={2} style={{ textAlign: "right" }}>
                                        <div className="mb-4 mb-md-0">
                                            <Link>
                                                <img
                                                    src={jobListDetails.companyImg}
                                                    alt=""
                                                    className="img-fluid rounded-3"
                                                    style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                                />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ textAlign: "left" }}>
                                        <div className="mb-2 mb-md-0">
                                            <h5 className="fs-18 mb-1">
                                                <Link to={jobDetail + jobListDetails.id} target="_blank" className="text-dark">
                                                    {jobListDetails.jobTitle}
                                                </Link>
                                            </h5>
                                        </div>
                                    </Col>

                                    <Col md={2}>
                                        <div className="mb-2 mb-md-0">
                                            <h5 className="fs-18 mb-1">
                                                <Link className="bg-success-subtle text-success fs-13 mt-1">
                                                    {jobListDetails.files}
                                                </Link>
                                            </h5>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
            </Row>
        </React.Fragment>
    )
};

export default ManageJobApplyList;