import Moment from 'moment';

export const checkJobPostExpireTime = (date_need) => {
    let jobPostExpire = "";
    if (date_need) {
        let dateNow = new Date();
        let dateNeed = new Date(date_need);
        let postExpireTime = dateNeed.getTime() - dateNow.getTime();
        let days = Math.floor(postExpireTime / (1000 * 60 * 60 * 24));
        let hours = Math.floor((postExpireTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((postExpireTime % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((postExpireTime % (1000 * 60)) / 1000);
        if (Moment(new Date).format("YYYYMMDD") == Moment(date_need).format("YYYYMMDD")) {
            if (Moment(new Date).format("HH") == Moment(date_need).format("HH")) {
                if (Moment(new Date).format("mm") == Moment(date_need).format("mm")) {
                    jobPostExpire = "Còn " + seconds + " giây để ứng tuyển";
                } else {
                    jobPostExpire = "Còn " + minutes + " phút để ứng tuyển";
                }
            } else {
                let hourExpired = 24 - Moment(new Date).format("HH");
                jobPostExpire = "Còn " + hourExpired + " giờ để ứng tuyển";
            }
        } else {
            if (Moment(new Date).format("YYYYMMDD") > Moment(date_need).format("YYYYMMDD")) {
                jobPostExpire = "Hết hạn";
            } else {
                (days == 0 && hours < 24) ? (jobPostExpire = "Còn " + hours + " giờ để ứng tuyển") : (jobPostExpire = "Còn " + days + " ngày để ứng tuyển");
            }
        }
        return jobPostExpire;
    }
    else {
        return "";
    }
}